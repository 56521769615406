import { FC, useEffect, ReactNode } from "react";
import { Box } from "grommet";

// Hooks
import usePermissions from "../../hooks/auth/usePermissions";
import useAuditTrailRecordInsert from "../../hooks/auth/useAuditTrail";

// Constants
import { AuditTrailTypes, UserPermissions } from "../../constants";

// Contexts
import { useStore } from "../../contexts/store";

/*
 * This wrapper is used for all pages that require user permissions
 * to view. Also, since each sensitive page requires this component,
 * it will be used to audit which will require passing in the page's
 * audit text label.
 */

interface IAuthorizerProps {
  canView?: UserPermissions;
  auditLabel?: string;
  children: ReactNode;
}

const Authorizer: FC<IAuthorizerProps> = ({
  canView,
  auditLabel,
  children
}) => {
  const { store } = useStore();
  const { canView: canViewPage } = usePermissions();
  const { postAuditTrailRecord } = useAuditTrailRecordInsert();

  useEffect(() => {
    postAuditTrailRecord(AuditTrailTypes.SCREEN, auditLabel || "");
  }, []);

  if (!store.user) {
    return (
      <Box align="center" justify="center" fill>
        <h1>Loading...</h1>
      </Box>
    );
  }

  if (canView && !canViewPage(canView)) {
    return (
      <Box align="center" justify="center" fill>
        <h1>Access Denied</h1>
      </Box>
    );
  }

  return <>{children}</>;
};

export default Authorizer;
