import { Dispatch } from "react";
import { GridActions, GridIDs, Placeholders } from "../../constants";
import { GridColumns } from "../../types/grid";
import { GridAction, GridStateType } from "../../contexts/grid/useGrid";

const onPriceScheduleChange = (
  value: any,
  previousValue: any,
  rowData: { [key: string]: any },
  columns: GridColumns[],
  grids: GridStateType,
  setGrid: Dispatch<GridAction>
): { changedData?: { [key: string]: any }; changedColumns?: GridColumns[] } => {
  if (value === previousValue) return {};

  // If price schedule changes and the price schedule tab's grid has already loaded, delete the grid so it can be refreshed to show the correct data.
  console.log(
    `Node Price Schedule changed from ${previousValue} to ${value}. Reloading Price Schedule grid.`
  );
  if (grids.get(GridIDs.NodeItems)) {
    setGrid({
      type: GridActions.delete,
      payload: {
        gridId: GridIDs.NodeItems,
        gridData: null
      }
    });
  }

  // Nothing to return
  return {};
};

const onMultiPlayerChange = (
  value: any,
  previousValue: any,
  rowData: { [key: string]: any },
  columns: GridColumns[],
  grids: GridStateType,
  setGrid: Dispatch<GridAction>
): { changedData?: { [key: string]: any }; changedColumns?: GridColumns[] } => {
  if (value === previousValue) return {};

  // If Multiplayer checkbox changes and the display tab's grid has already loaded, delete the grid so it can be refreshed to show the correct data.
  console.log(
    `Node Is Multiplayer changed from ${previousValue} to ${value}. Reloading Displays grid.`
  );
  if (grids.get(GridIDs.NodeDisplays)) {
    setGrid({
      type: GridActions.delete,
      payload: {
        gridId: GridIDs.NodeDisplays,
        gridData: null
      }
    });
  }

  // Nothing to return
  return {};
};

export const gridColumns: GridColumns[] = [
  {
    field: "NodeName",
    title: "Node",
    width: "475px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true,
    isPartOfUniqueKey: true
  },
  {
    field: "NodeDesc",
    title: "Description",
    width: "600px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "Health",
    title: "Health",
    width: "180px",
    defaultShow: true,
    customFilter: "status",
    filterable: true,
    sortable: true,
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "reddot.gif",
    statuses: [
      {
        value: "greendot.gif",
        color: "var(--carbon-green)",
        text: "Online"
      },
      {
        value: "yellowdot.gif",
        color: "var(--carbon-black)",
        text: "Error"
      },
      {
        value: "reddot.gif",
        color: "var(--carbon-red)",
        text: "Offline"
      }
    ]
  },
  {
    field: "UpdatesFilter",
    title: "Updates",
    width: "180px",
    defaultShow: true,
    customFilter: "status",
    filterable: true,
    sortable: true,
    resizable: true,
    editable: false,
    editor: "status",
    required: false,
    canBeSaved: false,
    defaultValue: "Unprocessed",
    statuses: [
      {
        value: "Unprocessed",
        color: "var(--carbon-black)",
        text: "Unprocessed"
      },
      {
        value: "Confirmed",
        color: "var(--carbon-green)",
        text: "Confirmed"
      },
      {
        value: "Processing",
        color: "var(--carbon-yellow)",
        text: "Processing"
      },
      {
        value: "Ready to Send",
        color: "var(--carbon-yellow)",
        text: "Sending"
      },
      {
        value: "Sent",
        color: "var(--carbon-yellowgreen)",
        text: "Received"
      },
      {
        value: "Delivered",
        color: "var(--carbon-yellowgreen)",
        text: "Downloading"
      },
      {
        value: "Error",
        color: "var(--carbon-red)",
        text: "Error"
      },
      {
        value: "Pending",
        color: "var(--carbon-blue)",
        text: "Scheduled"
      }
    ]
  },
  {
    field: "LastProcessed",
    title: "Last Processed",
    width: "210px",
    defaultShow: true,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  },
  {
    field: "PlayerTypeID",
    title: "Player Type",
    width: "160px",
    defaultShow: true,
    customFilter: "dropdown",
    editable: true,
    editor: "dropdown",
    required: true,
    canBeSaved: true,
    defaultValue: 1,
    dropItems: [
      {
        value: 1,
        text: "PC Windows"
      },
      {
        value: 2,
        text: "CL1"
      },
      {
        value: 3,
        text: "CL2"
      },
      {
        value: 4,
        text: "Tizen"
      },
      {
        value: 5,
        text: "WebOS"
      },
      {
        value: 6,
        text: "Philips Wave"
      },
      {
        value: 7,
        text: "CL1+"
      },
      {
        value: 8,
        text: "CE2"
      }
    ]
  },
  {
    field: "NodeID",
    title: "ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1,
    isPartOfUniqueKey: true
  },
  {
    field: "CompanyID",
    title: "CompanyID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    company: true,
    defaultValue: Placeholders.companyID,
    isPartOfUniqueKey: true,
    systemHidden: true
  },
  {
    field: "CompanyName",
    title: "Company",
    titlePlural: "Companies",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    editor: "lookup",
    required: false,
    valueField: "CompanyID",
    maxLength: 50,
    canBeSaved: false,
    companyMaster: true,
    defaultValue: Placeholders.companyName
  },
  {
    field: "NodeTypeID",
    title: "NodeTypeID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "NodeTypeName",
    title: "Node Type",
    titlePlural: "Node Types",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "NodeTypeID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "IsMultiPlayer",
    title: "Multiple Players",
    width: "150px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false,
    onChange: onMultiPlayerChange
  },
  {
    field: "AppConfiguration",
    title: "App Configuration",
    width: "350px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "note",
    required: false,
    canBeSaved: true
  },
  {
    field: "ForceSequentialDownloads",
    title: "Force Seq. Downloads",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "ForceSynchronizedSchedules",
    title: "Force Sync. Schedules",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "MoviePosterFormatID",
    title: "MoviePosterFormatID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "MoviePosterFormatName",
    title: "Movie Poster Fmt",
    titlePlural: "Movie Poster Fmts",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "MoviePosterFormatID",
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "PriceScheduleID",
    title: "PriceScheduleID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PriceScheduleName",
    title: "Price Schedule",
    titlePlural: "Price Schedules",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "lookup",
    required: false,
    valueField: "PriceScheduleID",
    maxLength: 50,
    canBeSaved: false,
    onChange: onPriceScheduleChange
  },
  {
    field: "PatchID",
    title: "PatchID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: true,
    systemHidden: true
  },
  {
    field: "PatchVersion",
    title: "Patch Version",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 50,
    canBeSaved: false
  },
  {
    field: "Address",
    title: "Address",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "City",
    title: "City",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "State",
    title: "State",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "Zip",
    title: "Zip",
    width: "150px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 10,
    canBeSaved: true
  },
  {
    field: "PhoneNumber",
    title: "Phone Number",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "ContactName",
    title: "Contact Name",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 100,
    canBeSaved: true
  },
  {
    field: "ContactEmail",
    title: "Contact Email",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "VigilixID",
    title: "PC ID",
    titlePlural: "PC IDs",
    width: "150px",
    defaultShow: false,
    // will, 2/24/22: changed from 'text' to 'numeric' to fix lookup error. Note: does not allow for partial matches i.e. 'contains'
    filter: "numeric",
    editable: true,
    editor: "lookup",
    required: false,
    canBeSaved: true,
    valueField: "VigilixID"
  },
  {
    field: "Custom1",
    title: "Custom 1",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "Custom2",
    title: "Custom 2",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "Custom3",
    title: "Custom 3",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "Custom4",
    title: "Custom 4",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "Custom5",
    title: "Custom 5",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "DownloadPosters",
    title: "Download Posters",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: false
  },
  {
    field: "IsActive",
    title: "Active",
    width: "250px",
    defaultShow: false,
    filter: "boolean",
    editable: true,
    editor: "boolean",
    required: false,
    canBeSaved: true,
    defaultValue: true
  },
  {
    field: "MachineSerialNumber",
    title: "Machine Serial #",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 100,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "MachineMACAddress",
    title: "Machine MAC Address",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 30,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "MachineIPAddress",
    title: "Machine IP Address",
    width: "250px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    maxLength: 30,
    canBeSaved: false,
    systemHidden: true
  },
  {
    field: "MachineInfoLastUpdated",
    title: "Machine Info Last Update",
    width: "200px",
    defaultShow: false,
    filter: "text",
    editable: false,
    required: false,
    canBeSaved: false
  }
];
