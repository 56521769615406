import { GridColumns } from "../../types/grid";

export const gridColumns: GridColumns[] = [
  {
    field: "PatchVersion",
    title: "Version",
    width: "280px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 50,
    canBeSaved: true
  },
  {
    field: "PatchDesc",
    title: "Description",
    width: "425px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: false,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "PatchFilePath",
    title: "32-Bit File Path",
    width: "530px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "PatchFilePath64Bit",
    title: "64-Bit File Path",
    width: "390px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "text",
    required: true,
    maxLength: 256,
    canBeSaved: true
  },
  {
    field: "PlayerTypeID",
    title: "Player Type",
    width: "200px",
    defaultShow: true,
    filter: "text",
    editable: true,
    editor: "dropdown",
    required: false,
    canBeSaved: true,
    defaultValue: null,
    dropItems: [
      {
        value: null,
        text: "Choose Type"
      },
      {
        value: 1,
        text: "PC Windows"
      },
      {
        value: 2,
        text: "CL1"
      },
      {
        value: 3,
        text: "CL2"
      },
      {
        value: 4,
        text: "Tizen"
      },
      {
        value: 5,
        text: "WebOS"
      },
      {
        value: 6,
        text: "Philips Wave"
      },
      {
        value: 7,
        text: "CL1+"
      },
      {
        value: 8,
        text: "CE2"
      }
    ]
  },
  {
    field: "PatchID",
    title: "Patch ID",
    width: "150px",
    defaultShow: false,
    filter: "numeric",
    editable: false,
    required: false,
    canBeSaved: false,
    defaultValue: -1
  }
];
