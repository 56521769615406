import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "grommet";
import { useLocation, useParams, Link } from "react-router-dom";
import styled from "styled-components";

// Components
import CarbonGrid from "../../components/CarbonGrid/CarbonGrid";
import CarbonTabStrip from "../../components/CarbonTabs/CarbonTabStrip";
import CarbonGridToolbar, {
  GridToolbarActionLabels,
  GridToolbarItem
} from "../../components/CarbonGrid/Toolbar/CarbonGridToolbar";
import Authorizer from "../../components/Util/Authorizer";
import ConfirmationDialog from "../../components/Util/Dialogs/ConfirmationDialog";
import OctopusLoader from "../../components/Util/OctopusLoader";

// Contexts
import { useGrid } from "../../contexts/grid/useGrid";
import { useStore } from "../../contexts/store";

// Types & Constants
import { PackagesRefreshZonesContract } from "../../types";
import { GridType } from "../../types/grid";
import { gridColumns } from "./PackageZonesGridColumns";
import getTabs from "./PackagesTabs";

import {
  Placeholders,
  UserPermissions,
  GridIDs,
  CarbonIcons,
  EndpointResources,
  GridActions,
  StoreActions
} from "../../constants";

// Hooks
import usePackageOps from "../../hooks/packages/usePackagesOps";

const PackageZones = (): JSX.Element => {
  const { pathname } = useLocation();
  const { packageId } = useParams();
  const { setGrid } = useGrid();
  const { dispatch } = useStore();
  const pagePermission = UserPermissions.PackagesPerms;
  const tabs = getTabs(EndpointResources.Packages, packageId!);

  const [stencilId, setStencilId] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmRefreshZonesDialog, setShowConfirmRefreshZonesDialog] =
    useState<boolean>(false);

  const { refreshZonesFromPackage } = usePackageOps();

  const actionLabels: GridToolbarActionLabels = {
    singular: "package zone",
    plural: "package zones",
    titleSingular: "Package Zone",
    titlePlural: "Package Zones"
  };

  const currentTab = {
    index: tabs.findIndex((tab) => tab.resourceEndpoint === pathname),
    tab: tabs.find((tab) => tab.resourceEndpoint === pathname)
  };

  // will, 3/18/22: Added refresh zones confirmation dialog for clicking zone refresh button
  const RefreshZonesConfirmationDialog = (): JSX.Element => {
    return (
      <ConfirmationDialog
        bodyText={`This process will add any new zones that may have been added to the Stencil. Do you want to continue?`}
        onAcceptCallback={() => {
          setShowConfirmRefreshZonesDialog(false);
          refreshZonesFromPackageOperation();
        }}
        onRejectCallback={() => {
          setShowConfirmRefreshZonesDialog(false);
        }}
      />
    );
  };

  // will, 3/18/22: Handler for zone refresh added with dialog
  const refreshZonesFromPackageOperation = async () => {
    const payload: PackagesRefreshZonesContract = {
      packageId: parseInt(packageId || ""),
      stencilId
    };

    console.log(
      `Refreshing zones from Package for PackageID ${packageId}`,
      payload
    );

    setLoading(true);
    const result = await refreshZonesFromPackage(payload);
    setLoading(false);

    if (result.type === "success") {
      setGrid({
        type: GridActions.toggleRefreshGrid,
        payload: {
          gridId: GridIDs.PackageZones,
          gridData: true
        }
      });

      // Show success message
      dispatch({
        type: StoreActions.addNotification,
        payload: {
          message: "Zones refreshed successfully",
          messageType: "success",
          closable: false
        }
      });
    }
  };

  const gridSettings: GridType = {
    endpoints: {
      gridODataEndpoint: `/odata/${Placeholders.companyID}/${Placeholders.packagesTabPackageId}/gridpackagezones?$count=true&`,
      gridApiEndpoint: `/api/${Placeholders.packagesTabPackageId}/packagezones`,
      parentGridApiEndpoint: `/api/${Placeholders.companyID}/${EndpointResources.Packages}/${Placeholders.packagesTabPackageId}`,
      lookupEndpoints: [
        {
          lookupField: "MediaName",
          // cfoster 5/23/24 = changed 'static' to 'all' for adding videos to packages.
          endpoint: `/api/${Placeholders.companyID}/lookupmedia/all`
        },
        {
          lookupField: "ItemName",
          endpoint: `/api/${Placeholders.companyID}/lookupitems`
        }
      ]
    },
    records: [],
    total: 0,
    data: [],
    lookups: {},
    dataState: {
      pageSize: 25,
      take: 50,
      skip: 0,
      sort: []
    },
    dataItemKey: "PackageZoneID",
    singularEntityName: "package zone",
    pluralEntityName: "package zones",
    columns: gridColumns,
    showCopyBtn: false,
    showDeleteBtn: true,
    parentGridId: GridIDs.Packages,
    state: {
      editMode: false,
      insertMode: false,
      selectedState: {},
      selectedRow: -1,
      showQuickFilter: false,
      lastSaveDate: null,
      showSavingIndicator: false,
      lockoutMode: false
    }
  };

  const screenIcon = (
    <span className="material-icons-outlined">inventory_2</span>
  );

  // will, 3/18/22: Add button repurposed to trigger the zones refresh dialog
  const addBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Refresh Zones from Stencil",
    onClick: () => setShowConfirmRefreshZonesDialog(true),
    labelOverride: "Refresh Zones",
    iconOverride: CarbonIcons.Refresh
  };

  const previewBtn: GridToolbarItem = {
    show: true,
    enable: true,
    tooltip: "Open the viewer to preview selected package",
    toggledOn: false
  };

  return (
    <>
      <Helmet>
        <title>Carbon | Package Zones</title>
      </Helmet>
      <Authorizer
        canView={pagePermission}
        auditLabel={`${actionLabels.titlePlural} screen`}
      >
        {showConfirmRefreshZonesDialog && <RefreshZonesConfirmationDialog />}
        <Box align="start" fill>
          <Box direction="row" margin={{ bottom: "medium" }}>
            <StyledLink to="/app/packages">Packages</StyledLink>
            <span className="material-icons-outlined">chevron_right</span>
            <span
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "var(--carbon-orange)"
              }}
            >
              {currentTab.tab?.tabTitle}
            </span>
          </Box>
          <CarbonGridToolbar
            gridId={GridIDs.PackageZones}
            screenIcon={screenIcon}
            getScreenTitleFromParentRow={(rowData: { [key: string]: any }) => {
              setStencilId(rowData.StencilID);
              return rowData.PackageName;
            }}
            getScreenSubtitleFromParentRow={(rowData: {
              [key: string]: any;
            }) => {
              return rowData.CompanyName;
            }}
            permissions={pagePermission}
            actionLabels={actionLabels}
            addBtn={addBtn}
            previewBtn={previewBtn}
          />
          <CarbonTabStrip carbonTabs={tabs} selectedTab={currentTab.index} />
          <Box fill className="carbon-grid-wrapper">
            {loading && <OctopusLoader />}
            <CarbonGrid
              gridId={GridIDs.PackageZones}
              gridSettings={gridSettings}
            />
          </Box>
        </Box>
      </Authorizer>
    </>
  );
};

const StyledLink = styled(Link)`
  font-size: 16px;
`;

export default PackageZones;
